.Main-Services {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    overflow: hidden;
}

.Service-banner {}

.Service-banner img {
    width: 100%;
    object-fit: cover;
    top: 0px;
    position: relative;
    height: 90vh;
}

img {
    max-width: 100% !important;
    height: auto;
}

.Service-content {
    position: absolute;
    bottom: 0%;
    width: 60%;
    left: 20%;
    text-align-last: center;
}

.Service-content h1 {
    font-size: 50px;
    font-family: 'space-Groteskm';
    color: #fff;
    font-weight: initial;
    letter-spacing: 1.44px;
    line-height: 70px;
    margin: 0% 0% 5% 0%;
    text-align: -webkit-center;
}


.flex{
    display: flex;
    width: 50%;
}
.flex1 {
    width: 100%;

}
.flex1 img {
    width: 100%;
    position: relative;
    // height: 25vh;
    object-fit: cover;

}
// start from after the banner 
.Serice-start {
    padding: 5% 10% 0% 10%
}

.Services-link {
    background-color: #f5f3f1;
    display: flex;
    padding: 5% 10% 10% 10%;
    align-items: center;

}

.serv-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.serv-left p {
    color: rgba(9,9,11,1);
    font-size: 18px;
    font-family: space-Groteskb;
    margin: 5% 0%;

}
.serv-left a {
    color: rgb(168, 152, 126);
    line-height: 30px;
    font-size: 18px;
    font-family: 'space-Groteskr';
}
.serv-left a:hover {
    color: rgba(168,152,126,0.75);

}

.serv-right {
    width: 60%;
}
.serv-right p{
    color: rgb(81, 80, 80);
    font-size: 20px;
    font-family: "space-Groteskr";
    margin: 5% 0%;
    letter-spacing: 0.44px;
    line-height: 1.5;
}

.ACHeliDelivery{
    display: flex;
    align-items: center;
}
.del-right{
    width: 50%;
    padding: 5% 0%;

}

.borr{
    border-bottom: 3px solid rgb(117 108 108);
    width: 10%;
    margin: 0% 5% !important;
}
.del-right h1{
    font-size: 30px;
    font-family: 'space-Groteskb';
    margin: 5% 5%;
}
.del-right a{
    font-size: 20px;
    font-family: "space-Groteskm";
    margin: 5% 5%;
    color: rgb(168, 152, 126);
}
.del-right a:hover{
    color: rgba(168,152,126,0.75);
}
.del-right p{
    color: rgb(81, 80, 80);
    font-size: 22px;
    font-family: "space-Groteskr";
    margin: 5% 5%;
    letter-spacing: 0.44px;
    line-height: 1.5;
}
.del-left{
    width: 50%;
    // border: 1px solid blue
}
.del-left img{
    // padding: 0% 10% 10% 10%;
    width: 100%;
    // top: -70px;
    position: relative;
}
.ACHeliCharter{
    display: flex;
    // margin-bottom: -10rem !important;
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 5% 0% 10% 0%;
    align-items: center;
}
.char-left{
    width: 50%;
    padding: 5% 0%;
}
.char-left h1{
    font-size: 30px;
    font-family: 'space-Groteskb';
    margin: 5% 5%;
}
.char-left p{
    color: rgb(81, 80, 80);
    font-size: 22px;
    font-family: "space-Groteskr";
    margin: 5% 5%;
    letter-spacing: 0.44px;
    line-height: 1.5;
}
.char-left a{
    font-size: 20px;
    font-family: "space-Groteskm";
    margin: 5% 5%;
    color: rgb(168, 152, 126);
}
.char-left a:hover{
    color: rgba(168,152,126,0.75);
}


.char-right {
width: 50%;
}
.char-right img{
    // padding: 0% 10% 10% 10%;
    width: 100%;
    position: relative;
}

.ACHeliSales{
    display: flex;
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 0% 0% 10% 0%;
    align-items: center;
    margin: 5% 0% 0% 0%;

}


.sales-left{
    width: 50%;
    // border: 1px solid red;
}
.sales-left img{
    width: 100%;
    // padding: 10% 10% 10% 10%;

}
.sales-right{
    width: 50%;
    padding: 5% 0%;
    // border: 1px solid red;
}
.sales-right h1 {
    font-size: 30px;
    font-family: "space-Groteskb";
    margin: 5% 5%;
}

.sales-right p{
    color: rgb(81, 80, 80);
    font-size: 22px;
    font-family: "space-Groteskr";
    margin: 5% 5%;
    letter-spacing: 0.44px;
    line-height: 1.5;
}
.sales-right a{
    font-size: 20px;
    font-family: "space-Groteskm";
    margin: 5% 5%;
    color: rgb(168, 152, 126);
}
.sales-right a:hover{
    color: rgba(168,152,126,0.75);
}

// 
.EngineTransportation{
    display: flex;
    margin: 5% 0% 0% 0%;
    align-items: center;
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 5% 0% 5% 0%;


}
.Eng-left{
    width: 50%;
    // border: 1px solid green ;
    padding: 5% 0%;
}
.Eng-left h1{
    font-size: 30px;
    font-family: "space-Groteskb";
    margin: 5% 5%;
}
.Eng-left p{
    color: rgb(81, 80, 80);
    font-size: 22px;
    font-family: "space-Groteskr";
    margin: 5% 5%;
    letter-spacing: 0.44px;
    line-height: 1.5;
}
.Eng-left a{
    font-size: 20px;
    font-family: "space-Groteskm";
    margin: 5% 5%;
    color: rgb(168, 152, 126);
}
.Eng-left a:hover{
    color: rgba(168,152,126,0.75);
}



.Eng-right{
    width: 50%;
    // border: 1px solid green ;
}
.Eng-right img{
    // padding: 0% 10% 10% 10%;
    width: 100%;
    // top: -70px;
    position: relative;
}


.Footer{
    border-top: 5px solid #3d4043;
}
.Footer p{
    text-align: -webkit-center;
}



@media  only screen and (min-width: 1024px) and (max-width: 1324px){
    .Service-content h1 {
        font-size: 40px;
    }  
    .Serice-start {
        padding: 0% 5% 0% 5%;
    }
    .serv-right p {
        font-size: 18px;
    }
    .Services-link {
        gap: 5%;
    }
    .del-right h1 {
        font-size: 25px;
    }
    .del-right p {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 1.4;
        margin: 3% 5%;
    }
    .char-left p {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 1.4;
        margin: 3% 5%;
    }
    .char-left h1 {
        font-size: 25px;
    }
    .sales-right h1 {
        font-size: 25px;
    }
    .sales-right p {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 1.4;
        margin: 3% 5%;
    }
    .Eng-left h1 {
        font-size: 25px;
    }
    .Eng-left p {
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 1.4;
        margin: 3% 5%;
    }
    .flex1 img {
        // height: 22vh;
    }
}




@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Service-content h1 {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 1px;
    }
    .Serice-start {
        padding: 0% 5% 0% 5%;
    }
    .serv-right p {
        font-size: 18px;
        line-height: 1.4;
    }
    .Services-link {
        padding: 5% 5% 10% 5%;
        gap: 5%;
    }
    .serv-left a {
        line-height: 25px;
    }
    .del-left {
        width: 40%;
    }
    .del-right p {
        font-size: 18px;
        line-height: 1.2;
        margin: 5% 0% 5% 5%;
    }
    .del-right {
        width: 60%;
    }
    .del-right h1 {
        font-size: 22px;
    }
    .char-right {
        width: 40%;
    }
    .char-left p {
        font-size: 18px;
        margin: 5% 5% 5% 0% !important;
        line-height: 1.2;
    }
    .char-left h1 {
        font-size: 22px;
        margin: 5% 5% 5% 0%;
    }
    .borr {
        width: 35%;
    }
    .char-left {
        width: 60%;
    }
    .sales-left {
        width: 40%;
    }
    .sales-right p {
        font-size: 18px;
        margin: 5% 0% 5% 5% !important;
        line-height: 1.2;
    }
    .sales-right h1 {
        font-size: 22px;
    }
    .sales-right {
        width: 60%;
    }
    .Eng-left p {
        font-size: 18px;
        margin: 5% 5% 5% 0% !important;
        line-height: 1.2;
    }
    .Eng-left h1 {
        font-size: 22px;
        margin: 5% 5% 5% 0%;
    }
    .Eng-right {
        width: 40%;
    }
    .Eng-left {
        width: 60%;
    }
    
}




@media only screen and (min-width: 481px) and (max-width: 767px) {
    .Service-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }
    .Service-content {
        bottom: 10% !important;
        width: 70% !important;
        left: 15% !important;
    }
    .Serice-start {
        padding: 0% 5% 0% 5% !important;
    }
    .Services-link {
        padding: 5% 5% 10% 5%  !important;
        flex-direction: column  !important;
    }
    .serv-right {
        width: 100%  !important;
        text-align: center !important;
    }
    .serv-left {
        width: 100%  !important;
        display: flex  !important;
        flex-direction: column  !important;
        align-items: center  !important;
    }
    .ACHeliDelivery {
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        padding: 10% 0% 10% 0% !important;
        margin: 10% 0% 0% 0% !important;
    }
    .del-left {
        width: 100% !important;
    }
    .del-right {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .del-right h1 {
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .del-right p {
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .borr {
        width: 50% !important;
    }
    .ACHeliCharter {
        padding: 20% 0% 10% 0%  !important;
        flex-direction: column-reverse  !important;
    }
    .char-right {
        width: 100%;
    }
    .char-left {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .char-left p {
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .char-left h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .ACHeliSales {
        padding: 10% 0% 0% 0% !important;
        flex-direction: column !important;
        margin: 10% 0% 0% 0%  !important;
    }
    .sales-left {
        width: 100%;
    }
    .sales-right {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .sales-right h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .sales-right p{
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .EngineTransportation {
        margin: 0% !important;
        padding: 20% 0% 5% 0% !important;
        flex-direction: column-reverse !important;
    }
    .Eng-left {
        width: 100% !important;
    }
    .Eng-right {
        width: 100%;
    }
    .Eng-left {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .Eng-left h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .Eng-left p{
        font-size: 18px !important;
        text-align-last: center !important;
    }
    
    .FirstMile{
        display: flex;
        border-top-width: 10px !important;
        border-top-color: #f5f3f1 !important;
        border-top-style: solid !important;
        flex-direction: column !important;
    }
    .char-left p {
        font-size: 18px !important;
        text-align-last: center !important;
        margin: 5% !important;
    }
    .sales-left1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 10%;
    }
    .firee {
        width: 90% !important;
    }
    .sales-left1 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .Eng-right1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .Eng-right1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 5% 0%;
        gap: 20px;
    }
}



/* For mobile phones: */
@media only screen and (max-width: 480px) {
    
    .Service-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }
    .Service-content {
        bottom: 10% !important;
        width: 70% !important;
        left: 15% !important;
    }
    .Serice-start {
        padding: 0% 5% 0% 5% !important;
    }
    .Services-link {
        padding: 5% 5% 10% 5%  !important;
        flex-direction: column  !important;
    }
    .serv-right {
        width: 100%  !important;
        text-align: center !important;
    }
    .serv-left {
        width: 100%  !important;
        display: flex  !important;
        flex-direction: column  !important;
        align-items: center  !important;
    }
    .ACHeliDelivery {
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        padding: 10% 0% 10% 0% !important;
        margin: 10% 0% 0% 0% !important;
    }
    .del-left {
        width: 100% !important;
    }
    .del-right {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .del-right h1 {
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .del-right p {
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .borr {
        width: 50% !important;
    }
    .ACHeliCharter {
        padding: 20% 0% 10% 0%  !important;
        flex-direction: column-reverse  !important;
    }
    .char-right {
        width: 100%;
    }
    .char-left {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .char-left p {
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .char-left h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .ACHeliSales {
        padding: 10% 0% 0% 0% !important;
        flex-direction: column !important;
        margin: 10% 0% 0% 0%  !important;
    }
    .sales-left {
        width: 100%;
    }
    .sales-right {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .sales-right h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .sales-right p{
        font-size: 18px !important;
        text-align-last: center !important;
    }
    .EngineTransportation {
        margin: 0% !important;
        padding: 20% 0% 5% 0% !important;
        flex-direction: column-reverse !important;
    }
    .Eng-left {
        width: 100% !important;
    }
    .Eng-right {
        width: 100%;
    }
    .Eng-left {
        width: 100% !important;
        padding: 5% 0% 0% 0% !important;
        text-align-last: center !important;
        text-align: -webkit-center !important;
    }
    .Eng-left h1{
        font-size: 24px !important;
        margin: 5% 5% !important;
    }
    .Eng-left p{
        font-size: 18px !important;
        text-align-last: center !important;
    }

    .FirstMile{
        display: flex;
        border-top-width: 10px !important;
        border-top-color: #f5f3f1 !important;
        border-top-style: solid !important;
        flex-direction: column !important;
    }
    .char-left p {
        font-size: 18px !important;
        text-align-last: center !important;
        margin: 5% !important;
    }
    .sales-left1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 10%;
    }
    .firee {
        width: 90% !important;
    }
    .sales-left1 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .Eng-right1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .Eng-right1 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 5% 0%;
        gap: 20px;
    }
}