.Main-ContactUs {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    overflow: hidden;
}

.ContactUs-banner {}

.ContactUs-banner img {
    width: 100%;
    object-fit: cover;
    top: 0px;
    position: relative;
    height: 90vh;
}

img {
    max-width: 100% !important;
    height: auto;
}

.ContactUs-content {
    position: absolute;
    bottom: 0%;
    width: 60%;
    left: 20%;
    text-align-last: center;
}

.ContactUs-content h1 {
    font-size: 50px;
    font-family: "space-Groteskl";
    color: #fff;
    letter-spacing: 1.44px;
    line-height: 70px;
    margin: 0% 0% 5% 0%;
    text-align: -webkit-center;
    font-weight: bold;
}

.Contact-Menu {
    width: 100%;
    display: flex;
    align-items: center;
    height: 90vh;
    background-color: #000;
    padding: 5% 0%;
}

.Contact-left {
    width: 50%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Contact-left h1 {
    font-family: "Times New Roman", Sans-serif;
    font-size: 70px;
    font-weight: 600;
    color: red;
}

.Contact-left p {
    font-size: 20px !important;
    font-family: "space-Groteskr";
    margin: 2% 0% !important;
    text-align: -webkit-center;
    line-height: 30px;
    color: #fff;
}

.Contact-right {
    width: 50%;
    height: 80vh;

}

.Contact-right img {
    max-width: 100% !important;
    height: 80vh;
    width: 100%;
    object-fit: contain;
}

@media only screen and (min-width: 1024px) and (max-width: 1324px) {
    .ContactUs-content h1 {
        font-size: 40px;
    }

    .Contact-left p {
        font-size: 18px !important;
    }

    .Contact-left h1 {
        font-size: 60px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .ContactUs-content h1 {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .Contact-Menu {
        height: 60vh;
    }

    .Contact-left h1 {
        font-size: 40px;
    }

    .Contact-left p {
        font-size: 18px !important;
        margin: 2% 0% !important;
    }

    .Contact-left {
        height: 60vh;
    }

    .Contact-right {
        height: 60vh;
    }

    .Contact-right img {
        height: 60vh;
    }
}




@media only screen and (min-width: 481px) and (max-width: 767px) {
    .ContactUs-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }
    .ContactUs-content {
        bottom: 10% !important;
    }
    .Contact-right img {
        max-width: 100% !important;
        width: 100%  !important;;
        height: auto !important;
    }
    .Contact-Menu {
        height: auto !important;
        padding: 0% !important;
        flex-direction: column-reverse !important;
    }
    .Contact-right {
        width: 100% !important;
        height: auto !important;
    }
    .Contact-left {
        width: 100% !important;
        height: auto !important;
    }
    .Contact-left p {
        font-size: 18px !important;
        line-height: 35px !important;
    }
    .Contact-left h1 {
        font-size: 40px !important;
        margin: 10% 0% 5% 0% !important;
    }
}



/* For mobile phones: */
@media only screen and (max-width: 480px) {
    .ContactUs-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }
    .ContactUs-content {
        bottom: 10% !important;
    }
    .Contact-right img {
        max-width: 100% !important;
        width: 100%  !important;;
        height: auto !important;
    }
    .Contact-Menu {
        height: auto !important;
        padding: 0% !important;
        flex-direction: column-reverse !important;
    }
    .Contact-right {
        width: 100% !important;
        height: auto !important;
    }
    .Contact-left {
        width: 100% !important;
        height: auto !important;
    }
    .Contact-left p {
        font-size: 18px !important;
        line-height: 35px !important;
    }
    .Contact-left h1 {
        font-size: 40px !important;
        margin: 10% 0% 5% 0% !important;
    }
}