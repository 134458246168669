.Main-AirRescueServices {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    object-fit: cover;
    overflow: hidden;
}

.AirRescueServices-banner {}

.AirRescueServices-banner img {
    width: 100%;
    object-fit: cover;
    top: 0px;
    position: relative;
    height: 90vh;
}

img {
    max-width: 100% !important;
    height: auto;
}

.AirRescueServices-content {
    position: absolute;
    bottom: 0%;
    width: 60%;
    left: 20%;
    text-align-last: center;
}

.AirRescueServices-content h1 {
    font-size: 50px;
    font-family: 'space-Groteskm';
    color: #fff;
    font-weight: initial;
    letter-spacing: 1.44px;
    line-height: 70px;
    margin: 0% 0% 5% 0%;
    text-align: -webkit-center;
}


.ACHeliSales1{
    display: flex;
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 5% 0% 10% 0%;
    align-items: center;
    flex-direction: column-reverse;
}
.sales-right1{
    width: 100%;
    padding: 2%;
    text-align: -webkit-center;
}
.sales-right1 h1{
    font-size: 30px;
    font-family: "space-Groteskb";
    margin: 2% 5%;
}
.firee{
    width: 30%;
}
.sales-left1{
    display: flex;
    justify-content: space-between;
    padding: 5% 0%;
}



.EngineTransportation1{
    display: flex;
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 5% 0% 10% 0%;
    align-items: center;
    flex-direction: column;
}
.Eng-left1{
    width: 100%;
    padding: 2%;
    text-align: -webkit-center;
}
.Eng-left1 h1 {
    font-size: 30px;
    font-family: "space-Groteskb";
    margin: 2% 5%;
}

.Eng-right1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0%;
}
.Eng-right1 img{
    width: 100%;
    position: relative;
}


.airAmbulance {
    border-top-width: 10px !important;
    border-top-color: #f5f3f1 !important;
    border-top-style: solid !important;
    padding: 0% 0% 10% 0%;
    margin: 5% 0% 0% 0%;
}

@media only screen and (min-width: 1024px) and (max-width: 1324px) {
    .AirRescueServices-content h1 {
        font-size: 40px;
    }
}




@media only screen and (min-width: 821px) and (max-width: 1324px) {
    .Serice-start {
        padding: 0% 5% 0% 5% !important;
        margin: 15% 0% 0% 0% !important;
    }
}