.Main-AboutUs {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    // height: 90vh;
    object-fit: cover;
    overflow: hidden;
}

.AboutUs-banner {}

.AboutUs-banner img {
    width: 100%;
    object-fit: cover;
    top: 0px;
    position: relative;
    display: block;
}

img {
    max-width: 100% !important;
    height: auto;
    display: block;
}

.AboutUs-content {
    position: absolute;
    bottom: 20%;
    width: 60%;
    left: 20%;
    text-align-last: center;
}

.AboutUs-content h1 {
    font-size: 50px;
    font-family: 'space-Groteskm';
    color: #fff;
    font-weight: initial;
    letter-spacing: 1.44px;
    line-height: 70px;
    margin: 0% 0% 5% 0%;
    text-align: -webkit-center;
}

.captain-contain {
    padding: 0% 10% 5% 10%;
}

.Captain-info {
    background-color: #f5f3f1;
    padding: 5% 10% 5% 10%;
    display: flex;
    justify-content: center;
}

.imgg {
    width: 10%;
    top: 70%;
    position: absolute;
}

.imgg img {
    width: 100%;
}

.captain-inside-info {
    text-align-last: center;
    width: 100%;
    border-bottom-width: 10px !important;
    border-bottom-color: #ffffff !important;
    border-bottom-style: solid !important;
    padding: 5% 0%;
}

.captain-inside-info h1 {
    font-size: 24px !important;
    font-family: 'space-Groteskb';
    margin: 2% 0% !important;
}

.captain-inside-info h2 {
    font-size: 22px !important;
    font-family: 'space-Groteskr';
    margin: 2% 0% !important;
}

.captain-inside-info p {
    font-size: 20px !important;
    font-family: 'space-Groteskr';
    margin: 2% 0% !important;
    text-align: -webkit-center;
}

.Content-main {
    padding: 0% 10% 5% 10%;
    margin: 10% 0% 0% 0%;

}

.Content {
    background-color: #F5F3CD;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.Content h1 {
    font-size: 40px !important;
    font-family: "space-Groteskb";
    margin: 2% 0% !important;
}

.Content h2 {
    font-size: 35px !important;
    font-family: "space-Groteskr";
    margin: 2% 0% !important;
}

.Content p {
    font-size: 20px !important;
    font-family: "space-Groteskr";
    margin: 2% 0% !important;
    text-align: -webkit-center;
}

.Content .borr1 {
    width: 100%;
    border-bottom-width: 10px !important;
    border-bottom-color: #ffffff !important;
    border-bottom-style: solid !important;
    padding: 2% 0% !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1324px) {
    .AboutUs-content h1 {
        font-size: 40px;
    }

    .captain-inside-info h1 {
        font-size: 22px !important;
    }

    .captain-inside-info h2 {
        font-size: 20px !important;
    }

    .captain-inside-info p {
        font-size: 18px !important;
    }

    .Content h1 {
        font-size: 35px !important;
    }

    .Content h2 {
        font-size: 30px !important;
    }

    .Content p {
        font-size: 18px !important;
    }


}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .AboutUs-content h1 {
        font-size: 35px;
        line-height: 40px;
        letter-spacing: 1px;
    }

    .imgg {
        width: 20%;
        top: 70%;
    }

    .Captain-info {
        padding: 5% 5% 5% 5%;
    }

    .captain-inside-info {
        padding: 10% 0% 5% 0%;
    }

    .captain-inside-info h1 {
        font-size: 22px !important;
    }

    .captain-inside-info h2 {
        font-size: 20px !important;
    }

    .captain-inside-info p {
        font-size: 18px !important;
        margin: 5% 0% !important;
    }

    .Content h1 {
        font-size: 35px !important;
    }

    .captain-contain {
        padding: 0% 10% 10% 10%;
    }

    .Content h2 {
        font-size: 30px !important;
    }

    .Content p {
        font-size: 18px !important;
        margin: 5% 0% !important;
    }
}



@media only screen and (min-width: 481px) and (max-width: 767px) {
    .AboutUs-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }

    .captain-contain {
        padding: 0% 6% 15% 6% !important;
    }

    .imgg {
        width: 25% !important;
        position: absolute !important;
        top: 75% !important;
    }

    .Captain-info {
        padding: 15% 10% 5% 10% !important;
    }

    .captain-inside-info p {
        font-size: 18px !important;
        margin: 6% 0% !important;
        text-align: -webkit-center;
    }

    .captain-inside-info h1 {
        font-size: 22px !important;
        margin: 5% 0% !important;
    }

    .captain-inside-info h2 {
        font-size: 20px !important;
        margin: 5% 0% !important;
    }

    .Content-main {
        padding: 0% 6% 15% 6% !important;
    }

    .Content h1 {
        font-size: 30px !important;
        margin: 5% 0% !important;
    }

    .Content h2 {
        font-size: 25px !important;
        margin: 5% 0% !important;
    }

    .Content p {
        font-size: 18px !important;
        margin: 6% 0% !important;
    }

}



/* For mobile phones: */
@media only screen and (max-width: 480px) {
    .AboutUs-content h1 {
        font-size: 35px !important;
        letter-spacing: 0.44px !important;
        line-height: 45px !important;
    }

    .captain-contain {
        padding: 0% 6% 15% 6% !important;
    }

    .imgg {
        width: 25% !important;
        position: absolute !important;
        top: 75% !important;
    }

    .Captain-info {
        padding: 15% 10% 5% 10% !important;
    }

    .captain-inside-info p {
        font-size: 18px !important;
        margin: 6% 0% !important;
        text-align: -webkit-center;
    }

    .captain-inside-info h1 {
        font-size: 22px !important;
        margin: 5% 0% !important;
    }

    .captain-inside-info h2 {
        font-size: 20px !important;
        margin: 5% 0% !important;
    }

    .Content-main {
        padding: 0% 6% 15% 6% !important;
    }

    .Content h1 {
        font-size: 30px !important;
        margin: 5% 0% !important;
    }

    .Content h2 {
        font-size: 25px !important;
        margin: 5% 0% !important;
    }

    .Content p {
        font-size: 18px !important;
        margin: 6% 0% !important;
    }
}