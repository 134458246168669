@font-face {
        font-family: "space-Groteskb";
        src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Bold.ttf") format(truetype);
}


@font-face {
        font-family: "space-Groteskr";
        src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Regular.ttf") format(truetype);
}


@font-face {
        font-family: "space-Groteskl";
        src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Light.ttf") format(truetype);
}


@font-face {
        font-family: "space-Groteskm";
        src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-Medium.ttf") format(truetype);
}


@font-face {
        font-family: "space-Grotesksb";
        src: url("../../fonts/Space_Grotesk/static/SpaceGrotesk-SemiBold.ttf") format(truetype);
}


.FB {
        font-family: 'space-Groteskb' !important;
}

.FR {
        font-family: 'space-Groteskr' !important;
}

.FL {
        font-family: 'space-Groteskl' !important;
}

.FM {
        font-family: 'space-Groteskm' !important;
}

.FSB {
        font-family: 'space-Grotesksb' !important;
}

.Main-Home {
        opacity: 1;
        display: flex;
        justify-content: center;
        top: 0px;
        position: relative;
        display: block;
}

.Main-Home img {
        max-width: 100%;
        width: 100%;
        top: 0px;
        position: relative;
        object-fit: cover;
}

.Main-Banner {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
}

.Main-Banner img {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        object-fit: cover;
}

.video-height {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
}

.home-content {
        position: absolute;
        top: 14%;
        width: 100%;
        text-align-last: center;
}

.Main-countUp{
        width: 100%;
        text-align-last: center;
        position: absolute;
        bottom: 26%;
        transform: translate(0%, -50%);   
}
.home-content h1 {
        color: red;
        font-size: 120px;
        font-family: times roman;
        margin: 0%;
}

.home-content p {
        color: #fff;
        font-size: 20px;
        font-family: times roman;
        font-style: italic;
        margin: 0%;

}

.Main-Home2 {
        border: 1px solid red;

}

@media only screen and (min-width: 1024px) and (max-width: 1324px) {
        .home-content p {
                font-size: 18px;
        }

        .home-content h1 {
                font-size: 60px;
        }
        #container {
                position: absolute;
                width: 70px !important;
                height: 70px !important;
                left: 0%;
            }
            .widd {
                width: 20%;
                margin: 0% 3%;
            }
            .countt1 span {
                font-size: 18px !important;
            }
            .countt2 span {
                font-size: 18px !important;
            }
            .countt3 span {
                font-size: 18px !important;
            }
            .countt-Par {
                width: 100% !important;
                display: flex  !important;
                gap: 4%  !important;
                align-items: center  !important;
            }
}



@media only screen and (min-width: 768px) and (max-width: 1023px) {
        .navbar a {
                padding: 5px 5px;
        }
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
        .home-content h1 {
                font-size: 50px;
        }

        .home-content p {
                font-size: 16px;
        }

        .Main-Home img {
                height: 80vh;
        }
}