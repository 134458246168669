.header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #2f2c2b;
  transition: height 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #3d4043;
  height: 10vh;
}

/* Apply smaller height when header is scrolled */
.header.shrink {
  height: 8vh;
  background-color: #2f2c2b;
}

/* Logo container */
.logo-container {
  margin-left: 50px;
  transition: margin-left 0.3s ease;
}

/* Shrunk logo */
.logo.shrunk {
  height: 30px;
}

/* Move logo towards left when header is shrunk */
.header.shrink .logo-container {
  margin-left: 30px;
}

/* Style for the logo */
.logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.logo h1 {
  font-family: "muli";
  font-size: 20px !important;
  color: #fff;
  margin: 0 !important;
  text-decoration: none !important;
}

.logo h1:active {
  /* Optional: define styles for when the h1 element is active */
}

/* Navbar container */
.navbar-container {
  margin-right: 50px;
  /* Adjust margin as needed */
  transition: margin-right 0.3s ease;
  /* Smooth transition for margin-right */
}

/* Move navbar-container towards right when header is shrunk */
.header.shrink .navbar-container {
  margin-right: 30px;
  /* Move the navbar container 5px towards right */
}

/* Navbar styles */
.navbar {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.navbar li {
  margin-right: 20px;
}

.navbar li:last-child {
  margin-right: 0;
}

.navbar a {
  text-decoration: none;
  color: #ffffff;
  padding: 10px 15px;
  display: block;
  font-family: 'space-Groteskb' !important;
  font-size: 18px;
}

.navbar a:hover {
  background-color: transparent;
  cursor: pointer;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown .dropdown-menu {
  display: none;
  position: absolute;
  top: calc(100% + 0px);
  /* Adjust top position as needed */
  left: 0;
  /* Align dropdown items to the left */
  background-color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: auto;
  /* Adjust width as needed */
  border: 1px solid transparent;
  padding: 10px 5px;
  float: left;
  border-radius: 10px;
  min-width: 14rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu a {
  padding: 10px 20px !important;
  /* Adjust padding as needed */
  color: #ffffff;
  display: block;
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-family: 'space-Groteskr' !important;
  // border-bottom: 1px solid #3d4043;
  text-align: left;
  /* Align text to the left */
}

.dropdown-menu a:hover {
  cursor: pointer;
}

.colorless {
  background-color: transparent !important;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  font-family: 'space-Groteskb' !important;
  font-size: 18px;
  cursor: pointer;
}

a:-webkit-any-link {
  color: #fff;
  cursor: pointer;
  text-decoration: none !important;
}

.dropdown-item_modify {
  width: 100%;
  border-bottom: 1px solid #3d4043;
}


.Mobile-Header {
  display: none;
}


@media only screen and (max-width: 820px) {
  .header {
    display: none;
  }


  .Head-L .logo-Mobile {
    padding: 4% 0%;
  }




  .Mobile-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #2f2f2f;
  }

  .Head-L {
    flex: 1;
    margin-left: 5%;
  }

  .Head-R {
    cursor: pointer;
    z-index: 99990;
    position: relative;
    scale: 2;
    margin-right: 5%;

  }

  .logo-Mobile img {
    width: 100px;
    z-index: 9999;
    position: relative;
  }

  .Side-Menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 75%;
    background-color: #2f2f2f;
    z-index: 999;
    transition: right 0.3s ease;
  }

  .Close-Button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
  }

  .Close-Button:hover {
    color: #ccc;
  }

  .Menu-List {
    text-decoration: none;
    list-style-type: none;
    top: 25%;
    position: relative;
  }

  .li-link {
    line-height: 50px;
    font-size: 20px;
    font-family: 'space-Groteskb';
    margin: 10%;
  }
}









@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar a {
    padding: 5px 5px;
  }
}





@media only screen and (min-width: 481px) and (max-width: 767px) {
  .Main-Home img {
    height: auto !important;
  }

  .home-content {
    top: 0% !important;
  }

  .home-content h1 {
    font-size: 45px !important;
    margin: 5% 5% 2% 5% !important;
  }

  .home-content p {
    font-size: 18px !important;
  }
}



/* For mobile phones: */
@media only screen and (max-width: 480px) {
  .Main-Home img {
    // height: 50vh !important;
  }

  .home-content {
    top: 0% !important;
  }

  .home-content h1 {
    font-size: 45px !important;
    margin: 5% 5% 2% 5% !important;
  }

  .home-content p {
    font-size: 18px !important;
  }


}