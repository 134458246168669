.dash {}

.countt-Par {
  width: 100% !important;
  display: flex;
  justify-content: space-evenly;
}

.countt {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  place-items: flex-start;
  padding: 0% 0% 0% 1.5%;
  justify-content: center;
  border-left: 2px solid red;


}

.countt span {
  font-size: 25px;
  font-family: "space-Grotesksb";
  color: #fff;
  text-align-last: start;
  z-index: 9999;
  position: relative;
}

.countt1 {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  place-items: flex-start;
  padding: 0% 0% 0% 1.5%;
  justify-content: center;
  // border: 1px solid red;
  position: absolute;
  /* width: 100px; */
  /* height: 100px; */

}

.countt1 span {
  font-size: 25px;
  font-family: "space-Grotesksb";
  color: #fff;
  text-align-last: start;
  z-index: 9999;
  position: relative;
}

.countt2 {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  place-items: flex-start;
  padding: 0% 0% 0% 1%;
  justify-content: center;
  // border: 1px solid red;
  position: absolute;
  /* width: 100px; */
  /* height: 100px; */

}

.countt2 span {
  font-size: 25px;
  font-family: "space-Grotesksb";
  color: #fff;
  text-align-last: start;
  z-index: 9999;
  position: relative;
}

.countt3 {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  place-items: flex-start;
  padding: 0% 0% 0% 1%;
  justify-content: center;
  // border: 1px solid red;
  position: absolute;
  /* width: 100px; */
  /* height: 100px; */

}

.countt3 span {
  font-size: 25px;
  font-family: "space-Grotesksb";
  color: #fff;
  text-align-last: start;
  z-index: 9999;
  position: relative;
}

.widd {
  width: 20vw;
}





// adding nrew

#container {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0%;
}

#halfclip {
  width: 50%;
  height: 100%;
  right: 0px;
  position: absolute;
  overflow: hidden;
  transform-origin: left center;
  animation: cliprotate 1.5s ease-in-out 0.25s;
}

.halfcircle {
  box-sizing: border-box;
  height: 100%;
  right: 0px;
  position: absolute;
  border: solid 6px transparent;
  border-top-color: blue;
  border-left-color: blue;
  border-bottom-color: blue;
  border-right-color: blue;
  border-radius: 50%;
}

#clipped {
  width: 200%;
  animation: rotate 1.5s ease-in-out;
}

#fixed {
  width: 100%;
  transform: rotate(135deg);
  animation: showfixed 1.5s ease-in-out 0.25s;
}

@keyframes cliprotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

@keyframes showfixed {
  0% {
    opacity: 0;
  }

  49.9% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

// till here


@media only screen and (min-width: 1024px) and (max-width: 1324px) {
  .Main-countUp {
    bottom: 10% !important;
  }

  .countt span {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 1325px) and (max-width: 1524px) {
  .Main-countUp {
    bottom: 15% !important;
  }
}




/* For mobile phones: */
@media only screen and (max-width: 480px) {
  .countt span {
    font-size: 18px;
    font-family: "space-Grotesksb";
    color: #fff;
    text-align-last: start;
    z-index: 9999;
    position: relative;
  }

  .countt-Par {
    width: 70% !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: unset;
    left: 15%;
    position: relative;
    padding: 10% 0% 0% 0%;
  }

  .Main-countUp {
    width: 100%;
    background-color: #091117;
    padding: 0% 0% 10% 0% !important;
    transform: translate(0%, -0%) !important;
    bottom: 0% !important;
    position: relative !important;
}
}


@media only screen and (min-width: 481px) and (max-width: 767px) {
  .countt span {
    font-size: 18px;
    font-family: "space-Grotesksb";
    color: #fff;
    text-align-last: start;
    z-index: 9999;
    position: relative;
  }

  .countt-Par {
    width: 70% !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: unset;
    left: 15%;
    position: relative;
    padding: 10% 0% 0% 0%;
  }

  .Main-countUp {
    width: 100%;
    background-color: #091117;
    padding: 0% 0% 10% 0% !important;
    transform: translate(0%, 0%) !important;
    bottom: 0% !important;
    position: relative !important;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .countt span {
    font-size: 18px !important;
}
}