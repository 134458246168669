.Main-Footer {
    padding: 5% 0% 0% 0%;
    width: 100%;
    text-align: -webkit-center;
}

.Main-Footer h1 {
    font-family: "Times New Roman", Sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: red;
}

.Main-Footer p {
    font-size: 20px !important;
    font-family: "space-Groteskr";
    margin: 2% 0% !important;
    text-align: -webkit-center;
}

.Main-Footer a {
    font-size: 20px !important;
    font-family: "space-Groteskr";
    margin: 2% 0% !important;
    text-align: -webkit-center;
    color: #0170B9;
    cursor: pointer;
}

.Borrr {
    border-bottom-width: 2px !important;
    border-bottom-color: #000 !important;
    border-bottom-style: solid !important;
    border-top-width: 2px !important;
    border-top-color: #000 !important;
    border-top-style: solid !important;
    padding: 2px;
}

.footer-img img {
    display: block;
    max-width: 100% !important;
    height: auto;
    width: 20%;
}

.bluee {
    color: #002262;
}





@media only screen and (min-width: 1024px) and (max-width: 1324px) {
    .Main-Footer h1 {
        font-size: 40px;
    }

    .Main-Footer p {
        font-size: 18px !important;
    }
}



@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Main-Footer h1 {
        font-family: "Times New Roman", Sans-serif;
        font-size: 35px;
        font-weight: 600;
        color: red;
    }

    .Main-Footer p {
        font-size: 18px !important;
        margin: 2% 6% !important;
    }


}




@media only screen and (min-width: 768px) {
    .Mobile-Main-Footer{
        display: none;
    }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
    .Main-Footer{
        display: none;
    }
    .Mobile-Main-Footer {
        padding: 5% 0% 0% 0%;
        width: 100%;
        text-align: -webkit-center;
    }
    
    .Mobile-Main-Footer h1 {
        font-family: "Times New Roman", Sans-serif;
        font-size: 50px;
        font-weight: 600;
        color: red;
    }
    
    .Mobile-Main-Footer p {
        font-size: 18px !important;
        line-height: 35px !important;
        font-family: "space-Groteskr";
        margin: 2% 5% !important;
        text-align: -webkit-center;
    }
    
    .Mobile-Main-Footer a {
        font-size: 18px !important;
        line-height: 35px !important;
        font-family: "space-Groteskr";
        margin: 2% 0% !important;
        text-align: -webkit-center;
        color: #0170B9;
        cursor: pointer;
}
}


/* For mobile phones: */
@media only screen and (max-width: 480px) {
    .Main-Footer{
        display: none;
    }
    .Mobile-Main-Footer {
        padding: 5% 0% 0% 0%;
        width: 100%;
        text-align: -webkit-center;
    }
    
    .Mobile-Main-Footer h1 {
        font-family: "Times New Roman", Sans-serif;
        font-size: 50px;
        font-weight: 600;
        color: red;
    }
    
    .Mobile-Main-Footer p {
        font-size: 18px !important;
        line-height: 35px !important;
        font-family: "space-Groteskr";
        margin: 2% 5% !important;
        text-align: -webkit-center;
    }
    
    .Mobile-Main-Footer a {
        font-size: 18px !important;
        line-height: 35px !important;
        font-family: "space-Groteskr";
        margin: 2% 0% !important;
        text-align: -webkit-center;
        color: #0170B9;
        cursor: pointer;
}

}